<template>
  <div>
    <app-datatable
      add-permission="add_expensetype"
      :headers="datatable.headers"
      :end-point="endPoint"
      :table-slots="datatable.tableSlots"
      :reset-table-data="datatable.resetTableData"
      :update-table-data="datatable.updateTableData"
      @data-loaded="handleDataLoaded()"
      @add-new="addNew()"
    >
      <template v-slot:created_at="slotProp">
        <span class="d-inline-block dir-ltr">
          {{ $_format_date_time(slotProp.item.created_at) }}
        </span>
      </template>

      <!-- Actions -->
      <template
        v-if="$_cans(['change_expensetype', 'delete_expensetype'])"
        v-slot:actions="slotProp"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdil-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-if="$_can('change_expensetype')"
              @click="updateRow(slotProp.item)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="info"
                  class="fs-18"
                >
                  mdil-pencil
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Edit') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="$_can('delete_expensetype')"
              @click="confirmDelete(slotProp.item.id)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="error"
                  class="fs-18"
                >
                  mdil-delete
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Delete') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </app-datatable>

    <!------------------- Dialogs ------------------->
    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="datatable.resetTableData = true"
      @update-table-data="datatable.updateTableData = true"
    />

    <confirmation
      :confirm-dialog="deleteDialog"
      @cancel="deleteDialog = false"
      @confirm="deleteRow()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddEdit from './AddEdit.vue'

export default {
  components: {
    AddEdit
  },

  data() {
    return {
      addEditDialog: false,
      deleteDialog: false,
      formData: {},
      editMode: false,
      id: 0,
      datatable: {
        resetTableData: false,
        updateTableData: false,
        headers: [
          { text: this.$t('Name'), value: 'name', sortable: true },
          { text: this.$t('User'), value: 'user', sortable: true },
          { text: this.$t('Created At'), value: 'created_at', sortable: true },
          { text: this.$t('Actions'), value: 'actions', sortable: false, align: 'center' },
        ],
        tableSlots: [
          'created_at',
          'actions',
        ]
      },
      endPoint: 'expense-type'
    }
  },

  mounted() {
    this.$_section_title({ title: 'Expense Types', icon: 'mdil-layers' })
  },

  methods: {
    handleDataLoaded() {
      this.datatable.resetTableData = false
      this.datatable.updateTableData = false
    },

    addNew() {
      this.formData = {}
      this.editMode = false
      this.addEditDialog = true
    },

    updateRow(data) {
      this.formData = { ...data }
      this.editMode = true
      this.addEditDialog = true
    },

    confirmDelete(id) {
      this.id = id
      this.deleteDialog = true
    },

    deleteRow() {
      axios.delete(this.endPoint + this.id + '/').then(() => {
        this.$_notify('Deleted successfully')
        this.deleteDialog = false
        this.datatable.resetTableData = true
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>